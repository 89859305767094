import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  allow_google_maps = false;
  constructor() { }

  ngOnInit() {
    console.log("init");
    
  }

  on_google_maps_allowed(event){
    console.log("Event came");
    console.log(event.target.checked);
    if (event.target.checked){
      this.allow_google_maps = true;
    } else {
      event.target.checked = true
    }
  }

}
