import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { DownloadsComponent } from './downloads/downloads.component';


const routes: Routes = [
  { path: '', redirectTo: '/About', pathMatch: 'full' },
  { path: 'About', component: AboutComponent },
  { path: 'Kontakt', component: ContactComponent },
  { path: 'Impressum', component: ImpressumComponent },
  { path: 'Datenschutz', component: DatenschutzComponent },
  { path: 'Downloads', component: DownloadsComponent },
  { path: '**', component: ImpressumComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
