import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {Angular2ImageGalleryModule} from 'angular2-image-gallery';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { BottomBarComponent } from './bottom-bar/bottom-bar.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { LogoComponent } from './logo/logo.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { DownloadsComponent } from './downloads/downloads.component';

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    BottomBarComponent,
    AboutComponent,
    ContactComponent,
    ImpressumComponent,
    LogoComponent,
    SideBarComponent,
    DatenschutzComponent,
    DownloadsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    Angular2ImageGalleryModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
